import React from 'react';


export class AppState {
	projectMap = {};
	categories;
	
	constructor(projects) {
	    projects.forEach(project => {
	        this.projectMap[this.getKey(project)] = project;
	    });
	    
        this.categories = this.sortProjectByCategory(projects);
	}

    sortProjectByCategory = (projects) => {
        var output = {};
    
        projects.forEach( project => {
            const category = project.category;
            if (category) {
                if (output[category]) {
                    output[category].push(project);
                }
                else {
                    output[category] = [project];
                }
            }
        });
    
        return output;
    }
    
    getKey(project) {
        let rgx = new RegExp("[^a-zA-Z0-9]", 'gi');
        return project.title.replace(rgx, "");
    }

}

export const AppContext = React.createContext();
