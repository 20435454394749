import React, { useContext } from "react";
import styled from 'styled-components';
import { AppContext } from './AppContext';
import { Link } from "react-router-dom";

const FooterContainer = styled.div`
    margin-top: 24px;
    padding: 24px 12px;
    font-size: 10px;
    color: #404448;
    text-align: center;
`;

const FooterNav = styled.ul`
    margin: 12px 0;
    padding: 0;

    li {
        display: inline-block;
        padding: 0 8px;
        border-right: 1px solid #888;
        text-transform: uppercase;
        line-height: 160%;
    }
    
    li:last-child {
        border-right: none;
    }
`;

export default function Footer(params)
{
    const context = useContext(AppContext);
    
    return <FooterContainer>
    <FooterNav>
        { Object.keys(context.categories).map(category => <li key={category}><Link to={"/category/" + category}>{category}</Link></li>)}
    </FooterNav>
    <div>Copyright &copy; Derek Chung</div></FooterContainer>;
}
