import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import { AppContext } from './AppContext';

const MainContainer = styled.table`
    position: relative;
    margin-bottom: 60px;
    border-left: 1px dotted #ccccf0;
    margin-left: auto;
    margin-right: auto;
    min-height: 80vh;
    
    td {
	    border-right: 1px dotted #ccccf0;
	    text-align: center;
	    vertical-align: middle;
	    padding: 2px 8px;
	    max-width: 72px;
    }
    
    h3 {
        color: #808088;
        margin: 2px;
        font-size: 8px;
        font-weight: bold;
        text-transform: capitalize;
    }
`;

const ProjectContainer = styled.div`
    box-sizing: border-box;
    padding: 4px 0;
    font-size: 5px;
     
    img.thumb {
        width: 32px;
        height: auto;
    }
    
    h4 {
        margin: 2px;
    }
    
    .timespan {
        color: #808099;
    }
    
    a:hover {
        font-size: 9px;
        text-decoration: none;
    }
`;


function CategoryProjects(props) {
    const { projects, context } = props;
    const category = projects[0].category;
    
    return <td>
        <h3><Link to={"/category/" + category}>{category}</Link></h3>
        {projects.map(project =>
        <ProjectContainer key={project.title}>
           { project.images.thumb && <Link to={"/project/" + context.getKey(project)}><img className="thumb" src={"/images/" + project.images.thumb} alt={project.title} /></Link> }
            <h4><Link to={"/project/" + context.getKey(project)}>{project.title}</Link></h4>
            <div className="timespan">{project.timespan}</div>
         </ProjectContainer>
    )}</td>
}

function Home(props) {

  const context = useContext(AppContext);
  const [currentProject, setCurrentProject] = useState(0);

  return (
      <MainContainer>
        <tbody><tr>
            { Object.keys(context.categories).map(name => <CategoryProjects projects={context.categories[name]} key={name} context={context} />) }
         </tr></tbody>
      </MainContainer>
  );
}

export default Home;
