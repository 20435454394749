import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import './App.css';

import { AppState, AppContext } from './AppContext';
import projects from './projectData.json';
import Home from './Home';
import Project from './Project';

const globalState = new AppState(projects);

function App() {
        
  return (
    <div className="App">
        <AppContext.Provider value={globalState}>
            <Router>
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route path="/project/:title" element={<Project />} />
                  <Route path="/category/:categoryname" element={<Project />} />
                </Routes>
            </Router>
		</AppContext.Provider>
    </div>
  );
}

function writeSiteMapXML()
{
    let xml = [];
    let baseUrl = "https://www.derekchung.org/";
    let today = formatDate();
    let rgx = new RegExp("[^a-zA-Z0-9]", 'gi');
    
    xml.push('<?xml version="1.0" encoding="UTF-8"?>');
    xml.push('<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">');
    xml.push('<url><loc>' + baseUrl + '</loc><lastmod>' + today + '</lastmod></url>')
    projects.forEach(project => {
        let px = '<url>';
        px += '<loc>' + baseUrl + 'project/' + encodeURIComponent(project.title.replace(rgx, "")) + '</loc>';
        px += '<lastmod>' + today + '</lastmod>';
        px += '</url>';
        
        xml.push(px);
    });
    
    xml.push('</urlset>');
    
    console.log(xml.join("\n"));
}

function formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

writeSiteMapXML();

export default App;
