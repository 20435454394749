import React from "react";
import styled from 'styled-components';
import { AppContext } from './AppContext';
import { Link, useParams } from "react-router-dom";

import Footer from "./Footer";
import { breakpoints } from "./theme";

const ProjectContainer = styled.div`
    box-sizing: border-box;
    padding: 4px 0;
    font-size: 18px;
     
    img.thumb {
        width: 32px;
        height: auto;
    }
    
    h4 {
        margin: 2px;
    }
    
    .timespan {
        color: #808099;
    }
`;

const ProjectGrid = styled.div`
    display: grid;
    grid-template-columns: 48px auto;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width: ${breakpoints[0]}) {
        display: block;
    }
`;

const Sidebar = styled.div`
    border-right: 1px dotted #888;
    padding: 32px 2px;
    
    img.homebutton {
        width: 32px;
        height: 32px;
        margin: 4px;
    }
    
    img.relatedProject {
        width: 24px;
        height: 24px;
    }
    
    .navItem {
        margin: 8px;
        font-size: 10px;
    }
    
    .divider {
        height: 1px;
        margin: 8px 0;
        border-top: 1px dotted #888;
    }
    
    @media (max-width: ${breakpoints[0]}) {
        padding: 2px;
        border-bottom: 1px dotted #888;
        
        .divider {
            display: inline-block;
            height: 32px;
            border-right: 1px dotted #888;
            margin: 4px;
        }
        
        .navItem {
            display: inline-block;
        }
    }
`;

const ProjectDetails = styled.div`
    .mainimage {
        padding: 16px;
        
        @media (max-width: ${breakpoints[0]}) {
            padding: 8px 0;
        }
    }
    
    .mainimage img {
        max-width: 640px;
        height: auto;
    
        @media (max-width: ${breakpoints[0]}) {
            max-width: 100%;
        }
    }
    
    .thumbgallery div {
        display: inline-block;
        padding: 2px 2px 2px 16px;
        cursor: pointer;
    }
    
    .thumbgallery img {
        max-width: 60px;
        height: auto;
        
        @media (max-width: ${breakpoints[0]}) {
            max-width: 40px;
        }
    }
    
    .category {
        font-size: 8px;
        text-transform: uppercase;
        margin-bottom: 6px;
    }
    
    .projectinfo {
        padding: 16px;
        font-size: 10px;
        color: #404448;
        border-bottom: 1px dotted #888;
    }
    
    .projectname {
        font-size: 16px;
        font-weight: bold;
        color: #404448;
    }
    
    .format {
        text-transform: uppercase;
    }

    .metadata, .links {
        padding 16px 16px 4px;
        font-size: 11px;
        
        h4 {
            font-weight: normal;
            text-transform: uppercase;
            margin: 12px 0;
        }
    }
    .paragraphSpace {
        margin: 8px 0;
    }
    .singleSpace {
        margin: 2px 0;
    }
`;

function MetadataItem(props) {
    const { item, itemClass } = props;
    
    if ((typeof item) === "string") {
        return <div className={itemClass || "metadataItem"}>{item}</div>;
    }
    else if (item.hasOwnProperty("url")) {
        return <div className={itemClass || "metadataItem"}><a href={item.url}>{item.text}</a></div>;
    }
    
    return null;
}

function MetadataBlock(props) {
    const { heading, textContent, itemClass } = props;

    if (!textContent) {
        return null;
    }
    
    let output = [];
    
    if (heading) {
        output.push(<h4 key="heading">{ heading }</h4>);
    }
    
    if (textContent instanceof Array) {
        if (textContent.length > 0) {
            textContent.forEach( (item, index) =>  { output.push(<MetadataItem key={index} itemClass={itemClass} item={item}></MetadataItem>) } );
        }
        else {
            return null;
        }
    }
    else {
        let paragraphs = textContent.split("\n");
        if (paragraphs.length > 1) {
            paragraphs.forEach( (paragraph, index) =>  { output.push(<p key={index}>{ paragraph }</p>) } );
        }
        else 
        {
            output.push(<div key="textContent">{textContent}</div>);
        }
    } 
    
    return <div className="metadata">{ output }</div>; 
}

class Project extends React.Component {

	constructor(props) {
		super(props);
  
		this.state = {
			project: null,
			imageIndex: -1
		}
	}
	
	componentDidMount() {
	    const { title, categoryname } = this.props.params;
		
		if (title) {
		    const key = title.replace(/-/gi, " ");
			this.setState({ project: this.context.projectMap[key],
			    imageIndex: -1 })
		}
		else if (categoryname) {
			this.setState({ project: this.context.categories[categoryname][0],
			    imageIndex: -1 })
		}
	}
		  
    componentDidUpdate(prevProps, prevState) {
        const { title, categoryname } = this.props.params;
		const { project } = this.state;
		
		let newProjectTitle = undefined;
		
		if (title) {
		    newProjectTitle = title;
        }
        else if (categoryname) {
            newProjectTitle = this.context.getKey(this.context.categories[categoryname][0]);
        }
        
        if (project && newProjectTitle && this.context.getKey(project) !== newProjectTitle )
        {
            this.setState({ 
                project: this.context.projectMap[newProjectTitle],
                imageIndex: -1 
            });
            window.scrollTo(0, 0);
        }
    }
 
    setMainImage = (i) => {
        this.setState({ imageIndex: i });
    }   
    
    render() {
        const { project, imageIndex } = this.state;
        let mainImagePath;
        let projectCategory;
        
        if (project) {
            if (project.images) {
                mainImagePath = (imageIndex > -1 ? project.images.gallery[imageIndex] : project.images.main);
            }
            if (project.category) {
                projectCategory = this.context.categories[project.category];
                if (projectCategory && projectCategory.length < 2) {
                    projectCategory = null;
                }
            }
        }
        
        return (
              <ProjectContainer>
                  { project && <ProjectGrid>
                     <Sidebar>
                        <Link to="/"><img className="homebutton" src={"/images/square/derek.jpg"} alt="Home"/></Link>
                        { projectCategory && <div className="divider"></div> }
                        { projectCategory && projectCategory.map( categoryProject =>             
                            <div className="navItem" key={categoryProject.title}><Link to={"/project/" + this.context.getKey(categoryProject)}>{
                                categoryProject.images.thumb ? <img className="relatedProject" src={"/images/" + categoryProject.images.thumb} alt={categoryProject.title} /> : categoryProject.title }
                            </Link></div>)}
                     </Sidebar>
                     <ProjectDetails>
                        <div className="projectinfo">
                            { project.category && <div className="category"><Link to={"/category/" + project.category}>{project.category}</Link></div> }
                            { project.title && <div className="projectname">{ project.title } </div> }
                            { project.format && <div className="format">{project.format}</div> }
                            { project.url && <div className="projectlink"><a href={project.url}>{project.url}</a></div> }
                            { project.timespan && <div className="timespan">{project.timespan}</div> }
                        </div>
                        <div className="mainimage">{ mainImagePath && <img src={ "/images/fullsize/" + mainImagePath } alt={project.title} />} </div>
                        { project.images && project.images.gallery && <div className="thumbgallery">
                            { project.images.gallery.map( (path, index) => { return <div key={index} onClick={ () => { this.setMainImage(index); } }><img src={ "/images/thumbs/" + path } alt={path} /></div> } )}
                            </div> }
                        <MetadataBlock heading="Description" textContent={project.description} itemClass="paragraphSpace" />
                        { project.metadata && project.metadata.map( (metadataGroup, index) => 
                            <MetadataBlock key={index} heading={metadataGroup.label} textContent={ metadataGroup.items} itemClass="singleSpace" /> ) }
                    </ProjectDetails>
                  </ProjectGrid> }
                  <Footer>Copyright &copy; Derek Chung</Footer>
              </ProjectContainer>
          );
    }
}
export default (props) => (
    <Project
        {...props}
        params={useParams()}
    />
);
Project.contextType = AppContext;
